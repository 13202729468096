import { Badge, Button, Checkbox, Collapse, FileInput, Input, Modal, Textarea } from 'react-daisyui'
import { useProfile } from '@/stores/useAuth.ts'
import { useRef, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useDrives } from '@/stores/useDrives.ts'
import { IconBrandWhatsapp, IconPlus, IconTrash } from '@tabler/icons-react'
import { Album } from '@/models/album.ts'
import useCollapses from '@/hooks/useCollapses.tsx'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import { useHeyTheme } from '@/contexts/heyTheme.tsx'
import { HeyDrive } from '@/models/drive.ts'
import { ChromePicker } from 'react-color'
import DriveHeader from '@/components/DriveHeader.tsx'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { defaultThemes, ThemeDetails } from '@/components/Theme/ThemeSelection.tsx'
import ThemeColors from '@/components/Theme/ThemeColors.tsx'
import QRCodeDesigner, { QRCodeShape } from '@/components/QRCodeDesigner.tsx'
import { captureException } from '@/controller/sentryHelper.ts'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'

export default function SettingsPage() {
  const { driveId } = useParams<{ driveId: string }>()
  const { drives  } = useDrives()
  const drive = drives[driveId!]!
  const {profile} = useProfile()
  const { currentAlbum } = useFilesContext()!
  const imageModalRef = useRef<HTMLDialogElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [isDeletingImage, setIsDeletingImage] = useState(false)
  const { updateAlbum } = useClient()
  const queryClient = useQueryClient()

  if (!profile) {
    return <>Loading</>
  }

  const isDriveOwner = profile!.username === drive.author

  function onChangeImageClick() {
    imageModalRef.current?.showModal()
  }

  function onImageUploadClick() {
    console.log('click', imageInputRef.current)
    imageInputRef.current!.click()
  }

  async function onImageFileSelect(e: any) {
    console.log('ay', e)
    if (isUploadingFile) {
      return
    }

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setIsUploadingFile(true)
      try {
        const data = new FormData()
        data.append('thumbnail', file)
        await updateAlbum(currentAlbum!.id, data)
        await queryClient.invalidateQueries({
          queryKey: ['drive', drive.id],
        })
      } catch (e) {
        alert('Fehler: ' + JSON.stringify(e))
        console.error('error uploading new image', e)
      } finally {
        setIsUploadingFile(false)
        imageModalRef.current?.close()
      }
    }
  }

  async function onDeleteImageClick() {
    if (isDeletingImage) {
      return
    }

    if (confirm('Bild löschen?')) {
      setIsDeletingImage(true)
      try {
        await updateAlbum(currentAlbum!.id, { thumbnail: null })
        await queryClient.invalidateQueries({
          queryKey: ['drive', drive.id],
        })
      } catch (e) {
        alert('Fehler: ' + JSON.stringify(e))
        console.error('error deleting image', e)
      } finally {
        setIsDeletingImage(false)
        imageModalRef.current?.close()
      }
    }
  }

  return <>
    <DriveHeader onChangeImageClick={onChangeImageClick} />
    <div className='container max-w-[40rem] mx-auto px-4 flex flex-col items-center gap-2'>
      <ProfileSettings />
      { isDriveOwner && currentAlbum && drive && <DriveSettings /> }
    </div>
    <input type='file' ref={imageInputRef} className='hidden' accept='image/jpeg,image/png' onInput={onImageFileSelect} />
    <Modal ref={imageModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        Bild ändern
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5" disabled={isUploadingFile || isDeletingImage}>✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col items-center gap-3'>
          <Button disabled={isUploadingFile || isDeletingImage} loading={isUploadingFile} color='primary' onClick={onImageUploadClick}>Bild hochladen</Button>
          <Button disabled={isUploadingFile || isDeletingImage || currentAlbum?.thumbnail == null} loading={isDeletingImage} color='ghost' size='sm' onClick={onDeleteImageClick}>Bild entfernen</Button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}

function DriveSettings() {
  const { driveId } = useParams<{ driveId: string }>()
  const { drives } = useDrives()
  const drive = drives[driveId!]!
  const { currentAlbum: selectedAlbum } = useFilesContext()!
  const { updateDrive, updateAlbum, createAlbum, deleteAlbum } = useClient()
  const queryClient = useQueryClient()

  // const { albums } = useAlbums()
  const [driveTitle, setDriveTitle] = useState(drive.title)
  const [isSavingGeneral, setIsSavingGeneral] = useState(false)
  const [isSavingAlbum, setIsSavingAlbum] = useState(false)
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false)
  const [currentAlbum, setCurrentAlbum] = useState(drive.albums.find(album => selectedAlbum && album.id === selectedAlbum.id) || drive.albums.find(album => album.is_primary) || drive.albums[0])
  const [isCreatingNewAlbum, setIsCreatingNewAlbum] = useState(false)
  const { collapse: outerCollapse } = useCollapses()

  async function onGeneralSaveClick() {
    if (isSavingGeneral) {
      return
    }

    setIsSavingGeneral(true)
    try {
      await updateDrive(drive.id, { title: driveTitle })
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e) {
      alert('Error saving: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingGeneral(false)
    }
  }

  function formDataForAlbumPartial(albumData: Partial<Album>) {
    const data = new FormData()
    data.append('title', albumData.title!)
    data.append('description', albumData.description!)
    data.append('is_private', albumData.is_private ? 'true' : 'false')
    data.append('is_upload_disabled', albumData.is_upload_disabled ? 'true' : 'false')
    data.append('is_unlisted', albumData.is_unlisted ? 'true' : 'false')
    data.append('is_games_disabled', albumData.is_games_disabled ? 'true' : 'false')
    if (albumData.thumbnail !== undefined) {
      data.append('thumbnail', albumData.thumbnail!)
    }

    return data
  }

  async function onAlbumSaveClick(album: Album, albumData: Partial<Album>) {
    if (isSavingAlbum) {
      return
    }

    setIsSavingAlbum(true)
    try {
      const updatedData = formDataForAlbumPartial(albumData)

      await updateAlbum(album.id, updatedData)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e) {
      alert('Error saving: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingAlbum(false)
    }
  }

  async function onAlbumDeleteConfirm(album: Album) {
    if (isDeletingAlbum) {
      return
    }

    setIsDeletingAlbum(true)
    try {

      await deleteAlbum(album.id)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
      // Fallbach after deleting
      setIsCreatingNewAlbum(true)
    } catch (e: any) {
      console.error('error deleting', e)
      if (e && e.response && e.response.status === 409) {
        alert('Es sind noch Dateien im Album vorhanden. Bitte löschen oder in ein anderes Album verschieben.')
      } else {
        alert('Error deleting: ' + JSON.stringify(e))
      }
    } finally {
      setIsDeletingAlbum(false)
    }
  }

  async function onAlbumCreate(albumData: Partial<Album>) {
    if (isSavingAlbum) {
      return
    }

    try {
      const data = formDataForAlbumPartial(albumData)

      const newAlbumRes = await createAlbum(drive.id, data)
      const newAlbum: Album = newAlbumRes.data
      console.log('newAlbum', newAlbum)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
      // Not working yet idk why
      // setCurrentAlbum(newAlbum)
    } catch (e) {
      alert('Error creating album: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingAlbum(false)
    }
  }

  return <>
    <h2 className='text-3xl font-serif my-4 mb-5'>Mein FridaySnap</h2>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('general').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('general').onClick}>
        Allgemeine Einstellungen
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col gap-3'>
          <label className="form-control w-full max-w-xs">
          <span className="label-text">Event-Name</span>
            <Textarea value={driveTitle} onChange={e => setDriveTitle(e.target.value)}/>
          </label>
          <p>Weitere Einstellungen findest du unter "Alben".</p>
          <Button disabled={isSavingGeneral} loading={isSavingGeneral} className='mt-5' color='primary'
                    onClick={onGeneralSaveClick}>Speichern</Button>
        </div>
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('theme').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('theme').onClick}>
        Farben
      </Collapse.Title>
      <Collapse.Content>
        <ThemeSettings drive={drive} />
      </Collapse.Content>
    </Collapse>


    <Collapse className='collapse-arrow bg-secondary w-full' open={outerCollapse('albums').open}>
      <Collapse.Title className='font-semibold w-full' onClick={outerCollapse('albums').onClick}>
        Alben
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col w-[80vw] max-w-[36rem] gap-3'>
          <h3 className='text-xl font-semibold'>Alben</h3>
          <AlbumSelection
            drive={drive}
            selectedAlbum={isCreatingNewAlbum ? null : currentAlbum}
            setSelectedAlbum={(a) => {
              setIsCreatingNewAlbum(false)
              setCurrentAlbum(a)
            }}
            showScrollbar
            prependChild={(
              <div className='overflow-hidden inline-flex flex-col w-[6rem] mr-3 items-center'
                   onClick={() => setIsCreatingNewAlbum(true)}>
                <div
                  className='rounded-full w-[4rem] h-[4rem] mb-2 bg-primary inline-flex items-center justify-center relative'>
                  <IconPlus className='m-0 p-0 text-neutral absolute' size={40}/>
                </div>
                <div className={
                  'rounded-xl text-center text-sm px-2 w-full py-1 border-primary overflow-hidden overflow-ellipsis whitespace-nowrap ' + (isCreatingNewAlbum ? 'bg-primary text-neutral' : '')
                }>
                  Neu
                </div>
              </div>
            )}
          />
        </div>
        <div className='flex flex-col mt-5'>
          {
          isCreatingNewAlbum && <>
              <h3 className='text-xl font-semibold'>Neues Album erstellen</h3>
              <AlbumEdit
                onSubmit={onAlbumCreate}
                album={{
                  title: '',
                  description: '',
                }}
                isSaving={isSavingAlbum}
              />
            </>
          }
          {
            !isCreatingNewAlbum && currentAlbum &&  <>
              <h3 className='text-xl font-semibold'>Album bearbeiten</h3>
              <AlbumEdit
                originalAlbum={currentAlbum}
                key={currentAlbum.id}
                onSubmit={data => onAlbumSaveClick(currentAlbum, data)}
                album={currentAlbum}
                isSaving={isSavingAlbum}
                isDeleting={isDeletingAlbum}
                onDeleteConfirm={onAlbumDeleteConfirm}
              />
            </>
          }
        </div>
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('qrcode').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('qrcode').onClick}>
        QR-Code
      </Collapse.Title>
      <Collapse.Content>
        <QRCodeSettings drive={drive} />
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('liveshow').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('liveshow').onClick}>
        Liveshow
      </Collapse.Title>
      <Collapse.Content>
        <Liveshow drive={drive} />
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('download').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('download').onClick}>
        Komplett-Download
      </Collapse.Title>
      <Collapse.Content>
        <FullDownload drive={drive} />
      </Collapse.Content>
    </Collapse>


    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('help').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('help').onClick}>
        Hilfe und Support
      </Collapse.Title>
      <Collapse.Content>
        <HelpSupport drive={drive} />
      </Collapse.Content>
    </Collapse>
  </>
}

function AlbumEdit({ onSubmit, album, isSaving, isDeleting, onDeleteConfirm, originalAlbum }: { onSubmit: (album: Partial<Album>) => void, album: Partial<Album>, isSaving: boolean, originalAlbum?: Album, isDeleting?: boolean, onDeleteConfirm?: (originalAlbum: Album) => void }) {
  const { collapse: deleteAlbumCollapse} = useCollapses()
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const [albumData, setAlbumData] = useState<Partial<Album>>({
    title: album.title,
    description: album.description,
    is_private: album.is_private,
    is_upload_disabled: album.is_upload_disabled,
    is_unlisted: album.is_unlisted,
    is_games_disabled: album.is_games_disabled,
    thumbnail: undefined,
  })

  function updateAlbumDatas(data: Partial<Album>) {
    setAlbumData({
      ...albumData,
      ...data,
    })
  }

  function onFileSelect(e: any) {
    if (!e.target.files || !e.target.files[0]) {
      updateAlbumDatas({
        thumbnail: undefined,
      })
    }

    updateAlbumDatas({
      thumbnail: e.target.files[0],
    })
  }

  function onSubmitClick(e: any) {
    e.preventDefault()
    e.stopPropagation()
    onAlbumSaveClick()
  }

  function onAlbumSaveClick() {
    console.log('thumbnail', albumData)
    onSubmit(albumData)
  }

  return <>
    <form className='flex flex-col gap-3' onSubmit={onSubmitClick}>
      <label className="form-control w-full max-w-xs">
        <span className="label-text">Name</span>
        <Input maxLength={100} value={albumData.title} required
               onChange={e => updateAlbumDatas({title: e.target.value})}/>
      </label>
      <label className="form-control w-full max-w-xs">

        <span className="label-text">Vorschaubild</span>
        <div className='mt-1'>
          <div className='flex flex-row gap-5 items-center'>
            {(albumData.thumbnail || album.thumbnail) &&
              <div className='w-[5rem] h-[5rem] mb-2 bg-cover bg-center rounded-full'
                // @ts-ignore*
                   style={{backgroundImage: `url(${albumData.thumbnail ? URL.createObjectURL(albumData.thumbnail) : album.thumbnail})`}}></div>}
            {/*{albumData.thumbnail && <div className='w-[5rem] h-[5rem] mb-2' src={''''''''''}></div>}*/}
            {/*{album.thumbnail && !albumData.thumbnail && <img className='w-[5rem] h-[5rem] mb-2' src={album.thumbnail}/>}*/}
            {/*{ (album.thumbnail || albumData.thumbnail !== null) && <Button onClick={onPreviewImageRemove} color='neutral' size='sm'>Bild entfernen</Button> }*/}
          </div>
          {/*<Button color='neutral' size='sm' onClick={}>Auswählen</Button>*/}
          <FileInput className='w-full' accept='image/jpeg,image/png' onInput={onFileSelect}/>
        </div>
      </label>

      <label className="form-control w-full max-w-xs">
        <span className="label-text">Beschreibung</span>
        <Textarea maxLength={1024} value={albumData.description}
                  onChange={e => updateAlbumDatas({description: e.target.value})}/>
      </label>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_games_disabled}
                    onChange={() => updateAlbumDatas({is_games_disabled: !albumData.is_games_disabled})}></Checkbox>
          <div>
            Spiele anzeigen
          </div>
        </div>
      </label>
      <h3 className='font-semibold text-xl'>Privatsphäre</h3>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_private}
                    onChange={() => updateAlbumDatas({is_private: !albumData.is_private})}></Checkbox>
          <div>
            Gäste können alle Bilder/Videos sehen
            <p className='text-sm mt-1'>Wenn diese Option deaktiviert ist, können Gäste nur hochladen und ihre eigenen
              Bilder sehen.</p>
          </div>
        </div>
      </label>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_upload_disabled}
                    onChange={() => updateAlbumDatas({is_upload_disabled: !albumData.is_upload_disabled})}></Checkbox>
          <div>
            Bilder/Videos können hochgeladen werden
          </div>
        </div>
      </label>
      {!album.is_primary && <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_unlisted}
                    onChange={() => updateAlbumDatas({is_unlisted: !albumData.is_unlisted})}></Checkbox>
          <div>
            Dieses Album wird aufgelistet
            <p className='text-sm mt-1'>Wenn diese Option deaktiviert ist, können Gäste das Album nur mit einem Link
              aufrufen.</p>
          </div>
        </div>
      </label>}
      {originalAlbum && <>
        <Collapse className='collapse-arrow bg-secondary' open={deleteAlbumCollapse('delete').open}>
          <Collapse.Title className='px-0 font-semibold' onClick={deleteAlbumCollapse('delete').onClick}>
            <h3 className='font-semibold text-xl'>Album löschen?</h3>
          </Collapse.Title>
          <Collapse.Content className='px-0'>
            <div className='flex flex-col gap-5'>
              {originalAlbum.is_primary && <p>Das hier ist das Haupt-Album und kann nicht gelöscht werden.</p>}
              {!originalAlbum.is_primary && <>
                <p>Alben können nur gelöscht werden, wenn sie leer sind. Wir warnen dich, falls noch Dateien vorhanden
                  sind.</p>
                <div className='flex'>
                  <Badge className='flex-1 py-1 h-[unset]' variant='outline' size='lg' color='error'
                         onClick={() => deleteModalRef.current!.showModal()}>Album
                    löschen</Badge>
                </div>
              </>}
            </div>
          </Collapse.Content>
        </Collapse>
      </>}

      <Button disabled={isSaving} loading={isSaving} className='mt-5' color='primary'>Speichern</Button>
    </form>
    {originalAlbum && <Modal ref={deleteModalRef} responsive>
      <Modal.Header className='text-center'>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconTrash size='20'/>
          </div>
          Album löschen
        </div>
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body className='text-center flex flex-col gap-3'>
        <div className='flex flex-col gap-2 items-center'>
          <p className='mb-5'>
            Möchtest du dieses Album wirklich löschen?
          </p>
          <Button disabled={isDeleting} loading={isDeleting} className='px-5' color='error'
                  onClick={() => onDeleteConfirm!(originalAlbum)}>Löschen</Button>
          <form method="dialog">
            <Button disabled={isDeleting} color='ghost'>Abbrechen</Button>
          </form>
        </div>

      </Modal.Body>
    </Modal> }
  </>
}

function ProfileSettings() {
  const {profile} = useProfile()
  const [displayName, setDisplayName] = useState(profile!.display_name)
  const [isSaving, setIsSaving] = useState(false)
  const {updateProfile} = useClient()
  const queryClient = useQueryClient()
  const { collapse: outerCollapse } = useCollapses()

  async function onProfileSaveClick() {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    try {
      await updateProfile(profile!.username, {display_name: displayName})
      await queryClient.invalidateQueries({
        queryKey: ['profile'],
      })
    } catch (e) {
      alert('Error saving profile ' + JSON.stringify(e))
    } finally {
      setIsSaving(false)
    }
  }

  return <>
    <h2 className='text-3xl font-serif my-4 mb-5'>Mein Profil</h2>
    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('name').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('name').onClick}>
        Name
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col gap-3'>
          <Input value={displayName} onChange={e => setDisplayName(e.target.value)}/>
          <Button disabled={isSaving} loading={isSaving} className='mt-5' color='primary'
                  onClick={onProfileSaveClick}>Speichern</Button>
        </div>
      </Collapse.Content>
    </Collapse>
  </>
}

interface ThemeDetailsKeyData {
  display: string
  property: string
  color: string
}

function ThemeSettings({ drive }: { drive: HeyDrive }) {
  const [isSavingTheme, setIsSavingTheme] = useState(false)
  const { setTheme } = useHeyTheme()

  const driveDefaults = getDriveDefaultsFor(drive.drive_type)
  const defaultTheme = {
    primary: '#' + driveDefaults.color_primary,
    neutral: '#' + driveDefaults.color_neutral,
    content: '#' + driveDefaults.color_content,
  }
  const [currentTheme, setCurrentTheme] = useState<ThemeDetails>(drive.color_primary ? {
    primary: '#' + drive.color_primary,
    neutral: '#' + drive.color_neutral,
    content: '#' + drive.color_content,
  } : defaultTheme)
  const { updateDrive } = useClient()
  const queryClient = useQueryClient()
  const modalRef = useRef<HTMLDialogElement>(null)
  const [currentModalData, setCurrentModalData] = useState<ThemeDetailsKeyData | undefined>(undefined)

  async function onSaveClick() {
    if (isSavingTheme) {
      return
    }

    setIsSavingTheme(true)
    try {
      await updateDrive(drive.id, {
        color_primary: currentTheme.primary.slice(1),
        color_neutral: currentTheme.neutral.slice(1),
        color_content: currentTheme.content.slice(1),
      })
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e: any) {
      console.error('error saving theme', e)
      alert('Error savin theme' + JSON.stringify(e.response))
    } finally {
      setIsSavingTheme(false)
    }
  }

  function onColorChangeClick(data: ThemeDetailsKeyData) {
    setCurrentModalData(data)
    modalRef.current?.showModal()
  }

  function onThemeSelect(theme: ThemeDetails) {
    setTheme(
      theme.primary,
      theme.neutral,
      theme.content,
    )
    setCurrentTheme(theme)
  }

  const themePreviews = defaultThemes.map((theme, i) => <ThemeColors key={i} theme={theme} onClick={() => onThemeSelect(theme)} />)

  return <div className='flex flex-col gap-3'>
    <Modal ref={modalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {currentModalData?.display}
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col items-center'>
          {currentModalData && <ChromePicker
            disableAlpha
            // @ts-ignore
            color={currentTheme[currentModalData.property]}
              onChange={(c) => onThemeSelect({
                ...currentTheme,
                [currentModalData?.property]: c.hex,
              })}
            />
          }
          <Button disabled={isSavingTheme} loading={isSavingTheme} className='mt-5' color='primary' onClick={() => modalRef.current?.close()}>Fertig</Button>
        </div>
      </Modal.Body>
    </Modal>
    <h3 className='text-xl font-semibold'>Beispielfarben auswählen</h3>
    <div className='flex flex-row gap-2'>
      { themePreviews }
    </div>
    <h3 className='text-xl font-semibold'>Deine Farben anpassen</h3>
    <div className='flex flex-row gap-3'>
      <ThemeColors
        theme={currentTheme}
        big
        onPrimaryClick={() => onColorChangeClick({ display: 'Primär', property: 'primary', color: currentTheme.primary })}
        onNeutralClick={() => onColorChangeClick({ display: 'Hintergrund', property: 'neutral', color: currentTheme.neutral })}
        onContentClick={() => onColorChangeClick({ display: 'Inhalt', property: 'content', color: currentTheme.content })}
      />
      <div className='inline-flex flex-col gap-2 p-2 rounded'>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: 'Primär', property: 'primary', color: currentTheme.primary })}>Primär</div>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: 'Hintergrund', property: 'neutral', color: currentTheme.neutral })}>Hintergrund</div>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: 'Inhalt', property: 'content', color: currentTheme.content })}>Inhalt</div>
      </div>
      <div className='inline-flex flex-col gap-2 p-2 rounded text-[#040404]'>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer' onClick={() => onColorChangeClick({ display: 'Primär', property: 'primary', color: currentTheme.primary })}>🎨 auswählen</div>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer' onClick={() => onColorChangeClick({ display: 'Hintergrund', property: 'neutral', color: currentTheme.neutral })}>🎨 auswählen</div>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer' onClick={() => onColorChangeClick({ display: 'Inhalt', property: 'content', color: currentTheme.content })}>🎨 auswählen</div>
      </div>
    </div>
    <Button disabled={isSavingTheme} loading={isSavingTheme} className='mt-5' color='primary'
            onClick={onSaveClick}>Speichern</Button>
  </div>
}

function FullDownload({ drive }: { drive: HeyDrive }) {
  return <div className='flex flex-col gap-3'>
    <h3 className='text-xl font-semibold'>Komplett-Download anfordern</h3>
    <p>Du möchtest alle deine Bilder und Videos auf einmal herunterladen?</p>
    <p>Liebendgerne! Mit FridaySnap ist ein Komplett-Download inklusive. Bitte schreibe eine E-Mail an:<br /><a href={`mailto:hello@fridaysnap.de?subject=Komplettdownload (${drive.id})&body=Hallo :) Wir möchten gerne einen Komplettdownload anfordern.`} className='text-primary underline'>hello@fridaysnap.de (Einfach anklicken)</a></p>
  </div>
}

function Liveshow({ drive }: { drive: HeyDrive }) {
  const navigate = useNavigate()

  return <div className='flex flex-col gap-3'>
    <h3 className='text-xl font-semibold'>Liveshow</h3>
    <p className=''>Die Liveshow ist eine Diashow, die sich automatisch aktualisiert und immer die neuesten
      Bilder anzeigt.</p>
    <p>Ihr könnt sie also einfach auf einem Fernseher, Beamer, Tablet, Laptop etc. anzeigen lassen.</p>
    <p>Falls ihr ein privates Album anzeigen wollt, muss das anzeigende Gerät über den Brautpaar-Link eingeloggt sein.</p>
    <Button color='primary' onClick={() => navigate(`/d/${drive.id}/liveshow`)}>Zur Liveshow</Button>
  </div>
}

function HelpSupport({drive}: { drive: HeyDrive }) {
  return <div className='flex flex-col gap-3 items'>
    <h3 className='text-xl font-semibold'>Hilfe und Support</h3>
    <p>Ihr braucht Hilfe oder habt eine Frage? Wir helfen euch jederzeit <u>persönlich</u> weiter! Entweder über Mail oder WhatsApp: </p>
    <div className='flex flex-col gap-5 items-center mt-4'>
      <div>
        <Button color='success' className='text-[white] p-0 px-2'>
          <a className='w-full h-full flex flex-row items-center px-2 justify-center gap-1'
             href='https://wa.me/496987000170' target='_blank'
          >
            <IconBrandWhatsapp/>
            WhatsApp öffnen
          </a>
        </Button>
      </div>
      <p><b>E-Mail:</b> <a
        href={`mailto:hello@fridaysnap.de?subject=Frage oder Support (${drive.id})&body=Hallo :) Ich habe eine Frage: `} className='text-primary underline'>hello@fridaysnap.de</a></p>
    </div>
  </div>
}

function QRCodeSettings({drive}: { drive: HeyDrive }) {
  const [fg, setFg] = useState(`#${drive.qr_fg}`)
  const [bg, setBg] = useState(`#${drive.qr_bg}`)
  const [shape, setShape] = useState(drive.qr_shape)
  const [isSaving, setIsSaving] = useState(false)
  const { updateQrCode } = useClient()
  const queryClient = useQueryClient()

  async function onSaveClick() {
    if (isSaving) {
      return
    }

    setIsSaving(true)
    try {
      await updateQrCode(drive.id, {
        qr_fg: fg.substring(1),
        qr_bg: bg.substring(1),
        qr_shape: shape,
      })
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e: any) {
      console.error('error saving color', e)
      captureException(e, 'saving QR Code', () => ({ fg, bg, shape }))
      alert('Error savin color' + JSON.stringify(e.response))
    } finally {
      setIsSaving(false)
    }
  }

  return <div className='flex flex-col gap-3 items overflow-visible'>
    <h3 className='text-xl font-semibold'>Dein aktueller QR-Code</h3>
    {
      drive.qr_code_image && <img src={drive.qr_code_image} className='w-[10rem]'/>
    }
    {
      !drive.qr_code_image &&
      <p>Noch nicht verfügbar. Bitte speichere deinen QR-Code ab, damit er erneut generiert wird.</p>
    }
    <h3 className='text-xl font-semibold mt-3'>QR-Code gestalten</h3>
    <QRCodeDesigner fg={fg} setFg={setFg} bg={bg} setBg={setBg} shape={shape} setShape={setShape} svgBannerUrl='/fridaysnap-qrcode-banner.svg' shapes={{ [QRCodeShape.Rounded]: '/fridaysnap-qrcode.svg', [QRCodeShape.Square]: '/fridaysnap-qrcode-square.svg' }} />
    <Button onClick={onSaveClick} disabled={isSaving} loading={isSaving} color='primary'>Speichern</Button>
  </div>
}
