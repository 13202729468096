import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDrives } from '@/stores/useDrives.ts'
import { Button, FileInput, Loading, Tooltip } from 'react-daisyui'
import { useProfile } from '@/stores/useAuth.ts'
import { isStatusFinalState, useUploadQueue } from '@/stores/useUploadQueue.ts'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import { IconLock, IconPlus } from '@tabler/icons-react'
import FilesListing from '@/components/Files/FilesListing.tsx'
import FilesSlideshow from '@/components/Files/FilesSlideshow.tsx'
import { AlbumFile } from '@/models/albumFile.ts'
import { useInView } from 'react-intersection-observer'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import CreateProfileModal from '@/components/CreateProfileModal.tsx'
import DriveHeader from '@/components/DriveHeader.tsx'
import useFileSelection from '@/hooks/useFileSelection.tsx'
import FileSelection from '@/components/FileSelection.tsx'
import { t } from 'i18next'
import { Album } from '@/models/album.ts'

export function AlbumPage() {
  return (
    <>
      <AlbumPageContent />
    </>
  );
}

function AlbumPageContent() {
  const { driveId } = useParams<{ driveId: string }>()
  const { drives } = useDrives()
  const { profile } = useProfile()
  const profileCreateRef = useRef<HTMLDialogElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { registerFileUploads, uploadQueue } = useUploadQueue()
  const { ref, inView } = useInView()
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null)
  const isUploading = uploadQueue.some(file => !isStatusFinalState(file.status))
  const fileSelection = useFileSelection()
  const { isSelecting, setIsSelecting } = fileSelection

  const handleShowCreateProfile = useCallback(() => {
    profileCreateRef.current?.showModal();
  }, [profileCreateRef]);

  const drive = drives[driveId!]
  const { currentAlbum: album, currentFilesCollection, hasNextPage, fetchNextPage } = useFilesContext()!

  const isOwner = drive.author === profile?.username
  const showSelectButton = !drive.is_demo && isOwner && currentFilesCollection?.files.length || 0 > 0
  const navigate = useNavigate()

  function onUploadClick(e: any) {
    console.log('OnClick:', e)
    if (isUploading) {
      return
    }

    if (!profile) {
      e.preventDefault()
      e.stopPropagation()
      return handleShowCreateProfile()
    }

    inputFileRef.current!.click()
  }

  function onRegister() {
    setTimeout(() => {
      inputFileRef.current!.click()
    }, 200)
  }

  useEffect(() => {
    if (hasNextPage && fetchNextPage && inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  async function onFileSelect(e: any) {
    if (e.target.files.length > 0) {
      // console.log('FileSelect:', e)
      // const buff = await e.target.files[0].arrayBuffer()
      // console.log('buff', buff)
      // const dig = await crypto.subtle.digest('SHA-256', buff)
      // console.log('dig', dig)
      // const hashArray = Array.from(new Uint8Array(dig));
      // const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join('')
      // console.log('hash', hash)
      //
      // console.log('files', e.target.files)

      // @ts-ignore
      registerFileUploads(album!, Array.from(e.target.files).map(file => ({ file })))
    }
  }

  function onFileClick(file: AlbumFile) {
    const indexOfFile = currentFilesCollection!.files.findIndex(f => f.id === file.id)
    if (indexOfFile != null) {
      setSelectedFileIndex(indexOfFile)
    }
  }

  function navigateToAlbum(album: Album) {
    navigate(`/d/${drive.id}/${album.id}`)
  }

  return <div>
    <DriveHeader />
    {
      isOwner && drive.is_show_onboarding && <div className='container mx-auto flex justify-center mb-5'>
        <Button color='primary' onClick={() => navigate(`/d/${drive.id}/onboarding`)}>👋🏻 Einrichtung abschließen</Button>
      </div>
    }
    <div className="container mx-auto flex flex-col gap-3 justify-center">
      { drive.albums.length > 1 && <AlbumSelection drive={null} externalAlbums={drive.albums.filter(album => isOwner || !album.is_unlisted)} selectedAlbum={album!} setSelectedAlbum={navigateToAlbum} disabled={isSelecting}/> }
      {
        showSelectButton && <div className='flex flex-row gap-5 px-2'>
          <div className='flex-1'></div>
          <Button className='rounded-full' disabled={isUploading} size='sm' color='primary' onClick={() => setIsSelecting(!isSelecting)}>
            {isSelecting ? t('common.cancel').toLowerCase() : t('common.select').toLowerCase()}
          </Button>
        </div>
      }
      <div className='flex flex-row gap-5 px-2'>
        <div className='hidden'>
          <FileInput ref={inputFileRef} accept='image/jpeg,image/png,video/*' multiple onInput={onFileSelect}/>
        </div>
        {/*<Button color='primary' onClick={onUploadClick}>*/}
        {/*  <IconUpload/> Hochladen*/}
        {/*</Button>*/}
        {/*<Button variant='outline' color='primary' onClick={onSettingsClick}>*/}
        {/*  <IconSettings/> Einstellungen*/}
        {/*</Button>*/}
      </div>
    </div>
    { album?.is_private && <div className='container mx-auto px-4 flex justify-center pb-3 pt-1'>
      <Tooltip message='Gäste können Bilder und Videos nur hochladen, aber keine anderen sehen.' color='secondary'>
        <span className='text-sm opacity-50 flex flex-row gap-1 items-center cursor-pointer'>
          <IconLock size={20} /> {t('common.drivePrivate')}
        </span>
      </Tooltip>
    </div>}
    <div className='container mx-auto px-auto'>
      {
        currentFilesCollection && <FilesListing
          files={currentFilesCollection}
          fileSelection={fileSelection}
          onFileClick={fileSelection.isSelecting ? fileSelection.toggleFileSelection : onFileClick}
          prependChild={
            album != null && !album.is_upload_disabled && <div onClick={fileSelection.isSelecting ? undefined : onUploadClick} className={'cursor-pointer bg-primary flex items-center justify-center text-neutral rounded-xl aspect-square ' + (fileSelection.isSelecting ? 'opacity-30' : '')}>
            <div className='flex flex-col justify-center items-center'>
              { !isUploading && <IconPlus size={40} /> }
              { !isUploading && <div className='text-[0.75rem] flex flex-row items-center justify-center gap-1'>
                {/*<IconCamera /> <IconVideo />*/}
                {t('common.upload')}
              </div> }
            </div>
            { isUploading && <Loading color='neutral' />}
          </div>}
        />
      }
      {
        currentFilesCollection &&
        <FilesSlideshow files={currentFilesCollection.files} selectedFileIndex={selectedFileIndex}
                        onSelectFileIndex={setSelectedFileIndex}/>
      }
      <div className='w-1 h-1' ref={ref}/>
    </div>
    <CreateProfileModal album={album!} innerRef={profileCreateRef} onRegister={onRegister} />
    { isSelecting && <FileSelection fileSelection={fileSelection} />}
  </div>
}
