import { Button, Modal } from 'react-daisyui'
import { IconBrandWhatsapp, IconPencil } from '@tabler/icons-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRef } from 'react'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { useDrives } from '@/stores/useDrives.ts'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'
import { HeyDriveType } from '@/models/drive.ts'

export default function DriveHeader({ title, description, onChangeImageClick }: { title?: string,  description?: string, onChangeImageClick?: () => void}) {
  const { driveId } = useParams<{ driveId: string }>()
  const navigate = useNavigate()
  const demoModalRef = useRef<HTMLDialogElement>(null)
  const { currentAlbum: album } = useFilesContext()!
  const { drives } = useDrives()
  const drive = drives[driveId!]

  function onTitleClick() {
    navigate(`/d/${driveId}/${album!.id}`)
  }
  function onHeaderClick() {
    navigate(`/d/${drive.id}/${album!.id}`)
  }

  function onDemoUpgradeClick() {
    demoModalRef.current?.showModal()
  }

  if (!drive || !album) {
    return null
  }

  const driveDefaults = getDriveDefaultsFor(drive.drive_type)
  const defaultPicture = driveDefaults.previewPicture
  const demoUpgradeLink = {
    [HeyDriveType.WEDDING]: 'https://fridaysnap.de/produkt/qr-code-hochzeit-fotos/',
    [HeyDriveType.BIRTHDAY]: 'https://fridaysnap.de/produkt/qr-code-geburtstags-fotos/',
    [HeyDriveType.BABY_SHOWER]: 'https://fridaysnap.de/produkt/qr-code-baby-shower-party-fotos/',
    [HeyDriveType.COMPANY]: 'https://fridaysnap.de/produkt/qr-code-firmenfeier-fotos/',
  }[drive.drive_type]


  return <div className='flex flex-col'>
    <div className="flex flex-col gap-3 py-3 relative bg-primary pb-[3.5rem] mb-[5rem]">
      <div className='text-center'>
        <a
          className='bg-primary text-neutral font-serif text-center text-4xl my-3 block cursor-pointer'
          onClick={onHeaderClick}
          // style={{borderBottom: '1px solid rgba(255, 255, 255, 0.15)'}}
        >
          { drive.is_ha ? 'HeyAlbum' : 'FridaySnap' }
        </a>
      </div>
      <div className="container mx-auto px-3 pt-3 flex flex-col items-center gap-4">
        {/*<div className='min-w-[7rem] h-[7rem] md:w-[10rem] md:h-[10rem] rounded-full bg-cover'*/}
        {/*     style={{backgroundImage: album.thumbnail ? `url(${album.thumbnail})` : 'url(/public/logo.jpg)'}}/>*/}


        {/*<div className='flex flex-col items-center justify-center'>*/}
      </div>
      {/*<p className='px-3'>*/}
      {/*  {album.description}*/}
      {/*</p>*/}
      <div className='absolute left-[50%] top-[100%]' style={{transform: 'translateX(-50%) translateY(-50%)'}}>
        <div className='w-[8rem] h-[8rem] rounded-full bg-cover bg-center overflow-hidden'
             style={{backgroundImage: album.thumbnail ? `url(${album.thumbnail})` : `url(${defaultPicture})`}}>
          {
            onChangeImageClick &&
            <div className='h-full w-full bg-secondary bg-opacity-75 flex items-center justify-center' onClick={onChangeImageClick}>
              <Button color='primary'><IconPencil /> Bild ändern</Button>
            </div>
          }
        </div>
      </div>
      {drive.is_demo &&
        <div className='absolute bottom-0 w-full'>
          <div className='flex flex-row justify-between p-2'>
            <Button color='neutral' variant='outline' className='border-neutral text-neutral rounded-2xl border-2'
                    onClick={onDemoUpgradeClick}>
              Demo<br/>
              upgraden
            </Button>
            <Button color='ghost' className='text-neutral p-0'>
              <a className='w-full h-full flex flex-row items-center px-2 justify-center gap-1'
                 href='https://wa.me/496987000170' target='_blank'
              >
                <IconBrandWhatsapp width={20} height={20}/>
                <div>
                  WhatsApp<br/>
                  Beratung
                </div>
              </a>

            </Button>
          </div>
          <Modal ref={demoModalRef} responsive>
            <Modal.Header className='text-center'>
              Demo upgraden
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
                </button>
              </form>
            </Modal.Header>
            <Modal.Body className='text-center flex flex-col gap-3'>
              <p>Das hier ist eure persönliche FridaySnap Demo.</p>
              <p>Wenn ihr FridaySnap auch auf eurem Event verwenden möchtet, dann könnt ihr einfach <a
                className='text-primary underline font-bold'
                href={demoUpgradeLink} target='_blank'>FridaySnap auf unserer
                Produktseite
                kaufen</a>. Einfach die gleiche E-Mail wie bei dieser Demo angeben und eure Demo wird automatisch das
                Upgrade und ihr euren QR-Code erhalten 😊</p>
              <Button color='primary' className='p-0'>
                <a className='w-full h-full flex items-center justify-center'
                   href={demoUpgradeLink} target='_blank'
                >Demo upgraden</a>
              </Button>
              <form method="dialog" className='flex-1'>
                <Button variant='outline' color='neutral' className='w-full border-none'>Abbrechen</Button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      }
    </div>

    <div className='text-sm mx-auto text-center max-w-[20rem] px-2 mb-5'>
      <div className='mb-2'>
        {drive && <h1 className='font-serif text-3xl cursor-pointer mb-2 whitespace-pre-line' onClick={onTitleClick}>
          { title || drive.title || 'FridaySnap'}
        </h1>}
      </div>
      { description || album.description }
    </div>
  </div>
}
